<script setup>
const uniqueId = `copy_grad${Math.random().toString(36).substring(2, 7)}`;
</script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    :stroke="`url(#${uniqueId})`"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 9.16675C5 6.81008 5 5.63091 5.7325 4.89925C6.46417 4.16675 7.64333 4.16675 10 4.16675H12.5C14.8567 4.16675 16.0358 4.16675 16.7675 4.89925C17.5 5.63091 17.5 6.81008 17.5 9.16675V13.3334C17.5 15.6901 17.5 16.8692 16.7675 17.6009C16.0358 18.3334 14.8567 18.3334 12.5 18.3334H10C7.64333 18.3334 6.46417 18.3334 5.7325 17.6009C5 16.8692 5 15.6901 5 13.3334V9.16675Z"
      stroke-width="1.25"
    />
    <path
      d="M5 15.8334C4.33696 15.8334 3.70107 15.57 3.23223 15.1012C2.76339 14.6323 2.5 13.9965 2.5 13.3334V8.33341C2.5 5.19091 2.5 3.61925 3.47667 2.64341C4.4525 1.66675 6.02417 1.66675 9.16667 1.66675H12.5C13.163 1.66675 13.7989 1.93014 14.2678 2.39898C14.7366 2.86782 15 3.50371 15 4.16675"
      stroke-width="1.25"
    />
    <defs>
      <linearGradient :id="uniqueId" x1="23.25" y1="12.1519" x2="0.75" y2="12.1519" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F0899E" />
        <stop offset="0.244792" stop-color="#9617D2" />
        <stop offset="0.510417" stop-color="#4E0FFF" />
        <stop offset="0.75" stop-color="#2C60FD" />
        <stop offset="1" stop-color="#30F0F6" />
      </linearGradient>
    </defs>
  </svg>
</template>
